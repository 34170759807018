import React, { FC, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListSubheader,
  ListItem,
  Typography,
  Theme,
  withStyles,
  Tabs,
  Tab,
  createStyles
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import axios, { CancelTokenSource } from 'axios';
import CheckIcon from '@material-ui/icons/DoneAll';
import ConfirmIcon from '@material-ui/icons/AssignmentTurnedIn';
import PendingConfirmIcon from '@material-ui/icons/AssignmentLate';
import RescheduleIcon from '@material-ui/icons/Restore';
import { format, isToday, isYesterday } from 'date-fns';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { GET_EDIT_ALL_NOTIFICATION_BASE_URL, GET_EDIT_INDIVIDUAL_NOTIFICATION_BASE_URL } from 'constants/url';

interface Props {
  setOpenPopper?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingData?: boolean;
  notifications: NotificationModel[];
  setNotifications: React.Dispatch<React.SetStateAction<NotificationModel[]>>;
  setTotalNotification: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 400,
    borderRadius: '5px'
  },
  inline: {
    display: 'inline'
  },
  notFound: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  headerGrid: { color: '#000000', background: '#F5F8FA', padding: theme.spacing(2) },
  markButton: {
    color: '#000000',
    padding: theme.spacing(0)
  },
  listGridUnRead: {
    backgroundColor: '#E3F0F5',
    '&:hover': {
      backgroundColor: '#C8E1EA'
    }
  },
  checkIcon: {
    fontSize: 20,
    marginLeft: theme.spacing(1)
  },
  tabs: {
    padding: '0 8px'
  }
}));

const MarkAllButton = withStyles({
  label: {
    textTransform: 'capitalize'
  }
})(Button);

const AntTabs = withStyles({
  indicator: {
    backgroundColor: 'inherit'
  }
})(Tabs);

const AntTab: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: 'textSecondary',
      minWidth: 50,
      minHeight: 40,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      '&:hover': {
        color: '#53A0BE',
        opacity: 1
      },
      '&$selected': {
        color: '#53A0BE',
        background: theme.palette.primary.light,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: 10,
        fontWeight: theme.typography.fontWeightMedium
      },
      '&:focus': {
        color: '#53A0BE'
      }
    },
    selected: {}
  })
)(props => <Tab disableRipple {...props} wrapped />);

const ClientList: FC<Props> = props => {
  const classes = useStyles(props);

  const { isLoadingData, notifications, setNotifications, setTotalNotification, setOpenPopper } = props;
  const dataLength = notifications !== undefined ? notifications.length : 5;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const formatDate = (notifDate: string) => {
    if (isToday(new Date(notifDate))) {
      return `Today at ${format(new Date(notifDate), 'hh:mm a')}`;
    } else if (isYesterday(new Date(notifDate))) {
      return `Yesterday at ${format(new Date(notifDate), 'hh:mm a')}`;
    } else {
      return format(new Date(notifDate), 'EEEE hh:mm a');
    }
  };

  const handleChange = (event: any, selected: number) => {
    setSelectedTab(selected);
  };

  const handleOkConfirmation = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    await axios.put(GET_EDIT_ALL_NOTIFICATION_BASE_URL, { q: 'unread', status: 'read' }, { cancelToken: cancelTokenSource.token });
    const currentNotification = notifications;
    currentNotification.map(notif => {
      return (notif.status = 'read');
    });
    setNotifications(currentNotification);
    setTotalNotification(0);
    setOpenConfirmation(false);
    setOpenPopper && setOpenPopper(false);
  };

  const handleNotificationListItem = async (index: number, notifId: number, jobId: number) => {
    try {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      await axios.put(GET_EDIT_INDIVIDUAL_NOTIFICATION_BASE_URL(notifId), { status: 'read' }, { cancelToken: cancelTokenSource.token });
      const currentNotification = notifications;
      currentNotification[index].status = 'read';
      const count = currentNotification.filter(notif => notif.status === 'unread').length;

      setNotifications(currentNotification);
      setTotalNotification(count);

      window.open(`/jobs/${jobId}`, '_blank');
      setOpenPopper && setOpenPopper(false);
    } catch (err) {
      console.log(err);
    }
  };

  const renderSkeleton = () => {
    return [1, 2, 3, 4, 5].map(index => (
      <Fragment key={index}>
        <ListItem alignItems='flex-start' button>
          <Fragment>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Skeleton width={150} />
                <Skeleton width={150} />
              </Grid>
            </Grid>
          </Fragment>
        </ListItem>
        {index !== dataLength - 1 && <Divider />}
      </Fragment>
    ));
  };

  const renderNoResult = () => {
    return (
      <Typography variant='body2' align='center' color='textSecondary' className={classes.notFound}>
        No new notifications
      </Typography>
    );
  };

  const renderResultConfirmed = () => {
    return (
      notifications &&
      // eslint-disable-next-line array-callback-return
      notifications.map((notif, index) => {
        if (notif.type === 'CONFIRMED' || notif.type === 'PENDING CONFIRMED') {
          return (
            <Fragment key={index}>
              <ListItem
                alignItems='flex-start'
                className={notif.status === 'unread' ? classes.listGridUnRead : ''}
                button
                onClick={() => handleNotificationListItem(index, notif.id, notif.jobId)}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={1}>
                    {notif.type === 'CONFIRMED' ? <ConfirmIcon color='primary' /> : <PendingConfirmIcon color='primary' />}
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <Typography variant='subtitle1' align='left' gutterBottom>
                      {notif.title}
                    </Typography>
                    <Typography variant='body2' align='left' gutterBottom>
                      {notif.description}
                    </Typography>
                    <Typography variant='caption' align='left' color='textSecondary'>
                      {formatDate(notif.createdAt)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              {index !== dataLength - 1 && <Divider />}
            </Fragment>
          );
        }
      })
    );
  };

  const renderResultReschedule = () => {
    return (
      notifications &&
      // eslint-disable-next-line array-callback-return
      notifications.map((notif, index) => {
        if (notif.type === 'RESCHEDULE')
          return (
            <Fragment key={index}>
              <ListItem
                alignItems='flex-start'
                className={notif.status === 'unread' ? classes.listGridUnRead : ''}
                button
                onClick={() => handleNotificationListItem(index, notif.id, notif.jobId)}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={1}>
                    <RescheduleIcon color='primary' />
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <Typography variant='subtitle1' align='left' gutterBottom>
                      {notif.title}
                    </Typography>
                    <Typography variant='body2' align='left' gutterBottom>
                      {notif.description}
                    </Typography>
                    <Typography variant='caption' align='left' color='textSecondary'>
                      {formatDate(notif.createdAt)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              {index !== dataLength - 1 && <Divider />}
            </Fragment>
          );
      })
    );
  };

  const renderContent = () => {
    if (isLoadingData) {
      return renderSkeleton();
    } else {
      if (dataLength === 0) {
        return renderNoResult();
      } else {
        if (selectedTab === 0) {
          return renderResultConfirmed();
        } else {
          return renderResultReschedule();
        }
      }
    }
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={() => setOpenPopper && setOpenPopper(false)}>
        <List
          subheader={
            <ListSubheader disableGutters>
              <Grid container direction='row' justify='space-between' alignItems='flex-start' className={classes.headerGrid}>
                <Typography variant='h5'>New Notifications</Typography>
                <MarkAllButton
                  size='small'
                  className={classes.markButton}
                  onClick={() => setOpenConfirmation(true)}
                  disabled={notifications.filter(notif => notif.status === 'unread').length ? false : true}
                  disableRipple
                >
                  Mark all as read
                  <CheckIcon className={classes.checkIcon} />
                </MarkAllButton>
              </Grid>
              <AntTabs value={selectedTab} onChange={handleChange} centered variant='fullWidth' className={classes.tabs}>
                <AntTab value={0} label='Confirmed Job' />
                <AntTab value={1} label='Reschedule Job' />
              </AntTabs>
            </ListSubheader>
          }
          className={classes.list}
        >
          {renderContent()}
        </List>
      </ClickAwayListener>
      <StandardConfirmationDialog
        variant={'warning'}
        message='Are you sure want to make all as read?'
        okLabel='OK'
        cancelLabel='cancel'
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        onConfirm={handleOkConfirmation}
      />
    </Fragment>
  );
};

export default ClientList;
