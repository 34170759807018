export enum ServiceType {
  ADHOC = 'ADHOC',
  CONTRACT = 'CONTRACT',
  ADDITIONAL = 'ADDITIONAL'
}

export enum RepeatType {
  ADDITIONAL = 'Additional',
  ADHOC = 'ADHOC',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly'
}

export enum JobStatus {
  UNASSIGNED = 'UNASSIGNED',
  CONFIRMED = 'CONFIRMED',
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export enum JobNoteType {
  GENERAL = 'GENERAL',
  EQUIPMENT = 'EQUIPMENT'
}

export enum ShowPdfTypes {
  JOB = 'JOB',
  SERVICE = 'SERVICE',
  INVOICE = 'INVOICE'
}

export enum SettingCodes {
  COMPANY_SETTING = 'COMPANY_SETTING',
  NOTIFCOMPLETEJOBEMAIL = 'NOTIFCOMPLETEJOBEMAIL',
  DUPLICATECLIENT = 'DUPLICATECLIENT',
  TENANTSUBSCRIPIONEXP = 'TENANTSUBSCRIPIONEXP',
  OPERATING_TIME = 'OPERATING_TIME',
  PRICEVISIBILITY = 'PRICEVISIBILITY',
  EQUIPMENTNOTESVISIBILITY = 'EQUIPMENTNOTESVISIBILITY',
  JOBHISTORIESVISIBILITY = 'JOBHISTORIESVISIBILITY',
  WHATSAPPNOTIFICATION = 'WHATSAPPNOTIFICATION',
  EMAILNOTIFICATION = 'EMAILNOTIFICATION',
  PRICEREPORTVISIBILITY = 'PRICEREPORTVISIBILITY',
  FUTUREJOBSVISIBILITY = 'FUTUREJOBSVISIBILITY',
  INVOICEEMAILTEMPLATE = 'INVOICEEMAILTEMPLATE',
  CONTRACTEMAILTEMPLATE = 'CONTRACTEMAILTEMPLATE',
  JOBEMAILTEMPLATE = 'JOBEMAILTEMPLATE'
}

export enum ScheduleView {
  TIMELINE = 'resourceTimeline',
  TIMEGRID = 'resourceTimeGridDay',
  WEEK = 'dayGridWeek',
  MONTH = 'dayGridMonth',
  AGENDA = 'listMonth'
}

export enum PaymentMethod {
  CASH = 'Cash',
  PAYNOW = 'Paynow',
  CHEQUE = 'Cheque',
  UNPAID = 'Unpaid'
}
