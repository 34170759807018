enum SettingCodes {
  COMPANY_SETTING = 'COMPANY_SETTING',
  NOTIFCOMPLETEJOBEMAIL = 'NOTIFCOMPLETEJOBEMAIL',
  DUPLICATECLIENT = 'DUPLICATECLIENT',
  TENANTSUBSCRIPIONEXP = 'TENANTSUBSCRIPIONEXP',
  OPERATING_TIME = 'OPERATING_TIME',
  PRICEVISIBILITY = 'PRICEVISIBILITY',
  EQUIPMENTNOTESVISIBILITY = 'EQUIPMENTNOTESVISIBILITY',
  JOBHISTORIESVISIBILITY = 'JOBHISTORIESVISIBILITY',
  WHATSAPPNOTIFICATION = 'WHATSAPPNOTIFICATION',
  EMAILNOTIFICATION = 'EMAILNOTIFICATION',
  PRICEREPORTVISIBILITY = 'PRICEREPORTVISIBILITY',
  FUTUREJOBSVISIBILITY = 'FUTUREJOBSVISIBILITY',
  INVOICEEMAILTEMPLATE = 'INVOICEEMAILTEMPLATE',
  CONTRACTEMAILTEMPLATE = 'CONTRACTEMAILTEMPLATE',
  JOBEMAILTEMPLATE = 'JOBEMAILTEMPLATE',
  COLLATEITEMS = 'COLLATEITEMS',
  EXACTDATEVISIBILITY = 'EXACTDATEVISIBILITY'
}

export default SettingCodes;
