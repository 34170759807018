import React, { FC, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';
import BackIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Cancel';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ColorLegend from './ColorLegend';

import { isValid, subDays, addDays, isSameDay, subMonths, subWeeks, addMonths, addWeeks, format, startOfWeek, endOfWeek } from 'date-fns';
import { JobStatus, ScheduleView } from 'constants/enum';
import { ucWords } from 'utils';
import FilterTable from 'components/FilterTable';

interface Props {
  selectedTab: number;
  initialView: string;
  handleChangeScheduleView: (value: string) => void;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  publicHoliday: string;
  employeeMaster: Select[];
  employeeFilter: ColumnFilter[];
  setEmployeeFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  vehicleMaster: Select[];
  vehicleFilter: ColumnFilter[];
  setVehicleFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  districtMaster: Select[];
  districtFilter: ColumnFilter[];
  setDistrictFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    width: '45%'
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginBottom: 4
  }
}));

const ToolBar: FC<Props> = props => {
  const classes = useStyles();
  const {
    selectedTab,
    initialView,
    handleChangeScheduleView,
    selectedDate,
    setSelectedDate,
    active,
    setActive,
    publicHoliday,
    employeeMaster,
    employeeFilter,
    setEmployeeFilter,
    vehicleMaster,
    vehicleFilter,
    setVehicleFilter,
    districtMaster,
    districtFilter,
    setDistrictFilter
  } = props;

  const today = new Date();
  const jobStatus = Object.values(JobStatus);
  const scheduleView = Object.entries(ScheduleView).map(([key, value]) => ({ key, value }));

  const [selectedEmployee, setSelectedEmployee] = useState<Select[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<Select[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<Select[]>([]);

  const handleTodayButton = () => {
    setSelectedDate(new Date());
  };

  const handleBackButton = () => {
    let newDate = today;
    if (initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay')) {
      newDate = subDays(selectedDate, 1);
    } else if (!initialView.includes('dayGridWeek')) {
      newDate = subMonths(selectedDate, 1);
    } else {
      newDate = subWeeks(startOfWeek(selectedDate, { weekStartsOn: 1 }), 1);
    }
    setSelectedDate(newDate);
  };

  const handleNextButton = () => {
    let newDate = today;
    if (initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay')) {
      newDate = addDays(selectedDate, 1);
    } else if (!initialView.includes('dayGridWeek')) {
      newDate = addMonths(selectedDate, 1);
    } else {
      newDate = addWeeks(startOfWeek(selectedDate, { weekStartsOn: 1 }), 1);
    }
    setSelectedDate(newDate);
  };

  const handleChangeDate = (date: Date | null) => {
    if (isValid(date)) {
      setSelectedDate(date ? date : new Date());
    }
  };

  const handleSwitch = () => {
    setActive(!active);
  };

  const handleClearFilter = () => {
    setEmployeeFilter([]);
    setSelectedEmployee([]);
    setVehicleFilter([]);
    setSelectedVehicle([]);
    setDistrictFilter([]);
    setSelectedDistrict([]);
  };

  const handleDeleteEmployeeFilter = (index: number) => {
    const currentFilter = [...employeeFilter];
    const currentSelectedData = [...selectedEmployee];
    currentFilter.splice(index, 1);
    currentSelectedData.splice(index, 1);

    setEmployeeFilter(currentFilter);
    setSelectedEmployee(currentSelectedData);
  };

  const handleDeleteVehicleFilter = (index: number) => {
    const currentFilter = [...vehicleFilter];
    const currentSelectedData = [...selectedVehicle];
    currentFilter.splice(index, 1);
    currentSelectedData.splice(index, 1);

    setVehicleFilter(currentFilter);
    setSelectedVehicle(currentSelectedData);
  };

  const handleDeleteDistrictFilter = (index: number) => {
    const currentFilter = [...districtFilter];
    const currentSelectedData = [...selectedDistrict];
    currentFilter.splice(index, 1);
    currentSelectedData.splice(index, 1);

    setDistrictFilter(currentFilter);
    setSelectedDistrict(currentSelectedData);
  };

  const renderFilterHeader = () => {
    if (employeeFilter.length > 0 || vehicleFilter.length > 0 || districtFilter.length > 0) {
      return (
        <Grid container>
          <Grid item xs={1}>
            <Button color='primary' onClick={handleClearFilter}>
              <Typography variant='body1'>Clear Filter</Typography>
            </Button>
          </Grid>
          <Grid item xs={11}>
            {employeeFilter.map((value, index) => (
              <Chip
                label={value.columnName}
                deleteIcon={
                  <Tooltip title='Delete'>
                    <DeleteIcon color='disabled' />
                  </Tooltip>
                }
                style={{ margin: '0 8px 8px 0' }}
                onDelete={() => handleDeleteEmployeeFilter(index)}
              />
            ))}
            {vehicleFilter.map((value, index) => (
              <Chip
                label={value.columnName}
                deleteIcon={
                  <Tooltip title='Delete'>
                    <DeleteIcon color='disabled' />
                  </Tooltip>
                }
                style={{ margin: '0 8px 8px 0' }}
                onDelete={() => handleDeleteVehicleFilter(index)}
              />
            ))}
            {districtFilter.map((value, index) => (
              <Chip
                label={value.columnName}
                deleteIcon={
                  <Tooltip title='Delete'>
                    <DeleteIcon color='disabled' />
                  </Tooltip>
                }
                style={{ margin: '0 8px 8px 0' }}
                onDelete={() => handleDeleteDistrictFilter(index)}
              />
            ))}
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={7}>
          <Grid container>
            <Grid item xs={7}>
              <Button
                variant='contained'
                disableElevation
                onClick={handleTodayButton}
                color='primary'
                disabled={isSameDay(selectedDate, today) ? true : false}
              >
                Today
              </Button>
              <Tooltip
                title={
                  initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay')
                    ? 'Previous Date'
                    : !initialView.includes('dayGridWeek')
                    ? 'Previous Month'
                    : 'Previous Week'
                }
              >
                <IconButton onClick={handleBackButton} style={{ margin: '0 8px' }}>
                  <BackIcon />
                </IconButton>
              </Tooltip>
              {initialView.includes('dayGridWeek') ? (
                <TextField
                  variant='outlined'
                  disabled
                  margin='dense'
                  id='date'
                  value={`${format(startOfWeek(selectedDate, { weekStartsOn: 1 }), 'dd-MM-yyyy')} to ${format(
                    endOfWeek(selectedDate, { weekStartsOn: 1 }),
                    'dd-MM-yyyy'
                  )}`}
                />
              ) : (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    clearable
                    autoOk
                    margin='dense'
                    id='date'
                    value={selectedDate}
                    variant='inline'
                    inputVariant='outlined'
                    format={
                      initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay') ? 'EEE, dd MMMM yyyy' : 'MMMM yyyy'
                    }
                    views={
                      initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay')
                        ? ['year', 'month', 'date']
                        : ['year', 'month']
                    }
                    openTo={initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay') ? 'date' : 'month'}
                    onChange={handleChangeDate}
                    className={classes.datePicker}
                    InputProps={{
                      endAdornment: publicHoliday && (initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay')) && (
                        <InputAdornment position='end'>
                          <Tooltip title={publicHoliday}>
                            <InfoIcon color='error' />
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}

              <Tooltip
                title={
                  initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay')
                    ? 'Next Date'
                    : !initialView.includes('dayGridWeek')
                    ? 'Next Month'
                    : 'Next Week'
                }
              >
                <IconButton onClick={handleNextButton} style={{ margin: '0 8px' }}>
                  <NextIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justify='flex-end' xs={12} sm={5}>
          {selectedTab === 0 ? (
            <FilterTable
              masterData={employeeMaster}
              selectedData={selectedEmployee}
              setSelectedData={setSelectedEmployee}
              columnFilter={employeeFilter}
              setColumnFilter={setEmployeeFilter}
              label='Filter Employee'
            />
          ) : (
            <FilterTable
              masterData={vehicleMaster}
              selectedData={selectedVehicle}
              setSelectedData={setSelectedVehicle}
              columnFilter={vehicleFilter}
              setColumnFilter={setVehicleFilter}
              label='Filter Vehicle'
            />
          )}
          <FilterTable
            masterData={districtMaster}
            selectedData={selectedDistrict}
            setSelectedData={setSelectedDistrict}
            columnFilter={districtFilter}
            setColumnFilter={setDistrictFilter}
            label='Filter District'
          />
          <TextField
            select
            margin='dense'
            id='option'
            label='Schedule View'
            value={initialView}
            onChange={event => handleChangeScheduleView(event.target.value)}
            variant='outlined'
            autoComplete='off'
            style={{ minWidth: 120 }}
          >
            {scheduleView.map((option: any) => (
              <MenuItem key={option.key} value={option.value}>
                {ucWords(option.key)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems='center' style={{ marginTop: 16, marginBottom: 8 }}>
        {renderFilterHeader()}
      </Grid>
      <Grid container spacing={1} alignItems='center' style={{ marginTop: 16, marginBottom: 8 }}>
        <Grid item xs={3}>
          {(initialView.includes('resourceTimeline') || initialView.includes('resourceTimeGridDay')) && (
            <Grid container>
              <ButtonGroup size='small'>
                <Button variant='outlined' color={active ? 'primary' : 'default'} onClick={handleSwitch}>
                  All Day (12AM-12AM)
                </Button>
                <Button variant='outlined' color={active ? 'default' : 'primary'} onClick={handleSwitch}>
                  Operating Hours
                </Button>
              </ButtonGroup>
            </Grid>
          )}
        </Grid>
        <Grid item xs={9} container direction='row' justify='flex-end'>
          {jobStatus.map((value: any) => (
            <ColorLegend status={value} key={value} />
          ))}
          <Grid container xs={1} direction='column' alignItems='center' style={{ marginRight: 4, marginLeft: 4 }}>
            <Grid className={classes.dot} style={{ backgroundColor: '#967969' }} />
            <Typography variant='body2'>Time Off</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ToolBar;
