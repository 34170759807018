import { FC, Fragment } from 'react';
import { Checkbox, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getUnique } from 'utils';

interface Props {
  masterData: Select[];
  selectedData: Select[];
  setSelectedData: React.Dispatch<React.SetStateAction<Select[]>>;
  columnFilter: ColumnFilter[];
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 180,
    paddingBottom: 5,
    marginRight: theme.spacing(1)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53a0be'
    },
    '& .MuiIconButton-root': {
      color: '#53a0be'
    }
  }
}));

const FilterTable: FC<Props> = props => {
  const classes = useStyles();
  const { masterData, selectedData, setSelectedData, columnFilter, setColumnFilter, label } = props;

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const handleSetSelectedData = (data: Select[]) => {
    const selectedData: ColumnFilter[] = [];
    if (data) {
      const clearData = getUnique(data, 'id');
      clearData.map(value => {
        return selectedData.push({ filterKey: value.name, columnName: value.name, columnValue: value.id });
      });
      setSelectedData(clearData);
    }
    setColumnFilter(selectedData);
  };

  return (
    <Autocomplete
      id='filter'
      options={masterData}
      getOptionLabel={option => option.name}
      multiple
      autoHighlight
      disableCloseOnSelect
      value={selectedData}
      getOptionSelected={(option, value) => (value.name === option.name ? true : false)}
      classes={{ inputRoot: columnFilter.length > 0 ? classes.inputRoot : '' }}
      onChange={(event, value) => handleSetSelectedData(value)}
      renderTags={value => (
        <Typography variant='body1' color='primary' style={{ paddingLeft: 8 }}>
          {value.length} Selected
        </Typography>
      )}
      renderOption={(option, { selected }) => (
        <Fragment>
          <Checkbox icon={icon} checkedIcon={checkedIcon} color='primary' disableRipple className={classes.checkBox} checked={selected} />
          {option.name}
        </Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          margin='dense'
          label={label}
          variant='outlined'
          className={classes.textField}
          InputLabelProps={{
            style: { color: columnFilter.length > 0 ? '#53a0be' : '' }
          }}
        />
      )}
    />
  );
};

export default FilterTable;
